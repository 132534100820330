.welcome{
  padding-top: 3.5rem;
  padding-bottom: 3rem;
  background-color: $bg-color;
  @media (min-width: 992px){
    padding-top: 100px;
    padding-bottom: 55px;
  }
  @media (min-width: 1367px){
    padding-top: 116px;
    padding-bottom: 110px;
  }
  &__card{
    border-radius: 14px;
    height: 380px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media (min-width: 992px){
      margin-right: 5px;
      margin-left: 5px;
      height: 330px;
      border-radius: 10px;
    }
    @media (min-width: 1367px){
      margin-right: 10px;
      margin-left: 10px;
      height: 400px;
    }
    &-button{
      display: flex;
      height: 100%;
      border-radius: 14px;
      padding: 20px 0;
      background-color: rgba(50, 50, 50, 0.3);
      &:hover{
        background-color: rgba(50, 50, 50, 0.1);
      }
      &:hover button{
        color: $main-color;
        margin-bottom: auto;
        text-shadow: none;
        background-color: $bg-color;
      }
      button{
        color: #FFFFFF;
        border-radius: 4px;
        letter-spacing: 2px;
        font-weight: 500;
        min-width: 175px;
        max-width: 200px;
        border: transparent;
        margin: auto auto 0;
        text-shadow: 1px 2px 7px rgba(51, 51, 51, .9);
        transition: all ease .5s;
      }
    }
  }
}
.test{ /* test */
  overflow: auto;
  @media (min-width: 992px){
    overflow: hidden;
  }
  .row{
    width: 1280px;
    @media (min-width: 992px){
      width: auto;
    }
    .item{
      max-width: 320px;
      @media (min-width: 992px){
        max-width: 100%;
      }
    }
    .desktop{
      display: none;
      @media (min-width: 992px){
        display: block;
      }
    }
  }
}
.featured{
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  @media (min-width: 992px){
    padding-top: 85px;
    padding-bottom: 65px;
  }
  @media (min-width: 1367px){
    padding-top: 100px;
    padding-bottom: 90px;
  }
  .row{
    margin-bottom: 15px;
  }
}
/* Rectangle Out */
.hvr-rectangle-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-rectangle-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(23, 68, 68, 0.75);
  border-radius: 30px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
  color: white;
}
.hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: rgba(23, 68, 68, 0.75);
}