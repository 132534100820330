.property-details{
  &__info{
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    @media (min-width: 992px){
      padding-bottom: 5rem;
    }
    .info--header{
      font-family: 'Segoe Bold', sans-serif;
      h4{
        color: $main-color;
        font-size: 1.25rem;
        line-height: 26px;
        @media (min-width: 992px){
          font-size: 1.5rem;
        }
      }
    }
    .info--body{
      color: #555964;
      padding-bottom: 0;
      &.boder-button{
        @media (min-width: 992px){
          padding-bottom: 1.5rem;
          border-bottom: 1px solid #9797977A;
        }
      }
      p{
        font-family: 'Segoe', sans-serif;
        font-size: 1.1rem;
        line-height: 26px;
        strong{
          color: #000000;
        }
      }
    }
    .info--footer{
      @media (min-width: 992px){
        padding-top: 1.5rem;
        border-top: 1px solid #9797977A;
      }
      p{
        line-height: 24px;
        color: #555964;
        font-family: 'Segoe Italic', sans-serif;
      }
    }
    .info--fearure{
      border-radius: 10px;
      padding: 15px;
      box-shadow: 0px 15px 45px #0000001A;
      .icon__group{
        border-top: 0;
        .icon{
          position: relative;
          .tooltip{
            position: absolute;
            top: -42px;
            left: -38px;
            width: 104px;
            .tooltip-inner{
              background-color: #3F6C6C;
            }
            .arrow{
              left: 45px;
              &::before{
                border-top-color: #3F6C6C;
              }
            }
          }
          &:hover{
            .tooltip{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &__consult{
    padding-bottom: 4rem;
    @media (min-width: 992px){
      display: none;
    }
    a{
      letter-spacing: 2px;
      img{
        margin-bottom: -5px;
        margin-right: 5px;
      }
    }
  }
  &__share{
    padding-bottom: 5rem;
    ul{
      li{
        font-weight: 700;
        color: $main-color;
        line-height: 22px;
        letter-spacing: 2.57px;
        padding-right: 2rem;
        width: 100%;
        font-size: 15px;
        img{
          margin-right: 15px;
          max-width: 25px;
          max-height: 25px;
        }
        &.btn-print{
          @media (max-width: 992px){
            display: none !important;
          }
        }
      }
    }
    .open-contact-popup{
      cursor: pointer;
    }
    .open-popover{
      position: relative;
      cursor: pointer;
      .popover{
        left: 0;
        top: 100%;
        width: 100%;
        .popover-body{
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  &__location{
    padding-bottom: 5rem;
    iframe{
      width: 100%;
      height: 417px;
      margin-bottom: 15px;
    }
  }
}

.gallery-property{
  max-height: calc(100vh - 180px);
  overflow: hidden;
  margin-top: 150px;
  .item-image{
    overflow: hidden;
    &.large-image{
      max-height: calc(100vh - 180px);
      padding: 0;
      &.two-columns{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &.small-image{
      max-height: calc(((100vh - 180px) / 2) - 7px);
      margin-bottom: 15px;
      padding: 0;
      &:last-child{
        margin-bottom: 0;
      }
    }
    img{
      cursor: pointer;
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: center;
    }
  }
}


@media (max-width: 767px){
  .gallery-property{
    max-height: calc(110vh - 100px);
    overflow: hidden;
    margin-top: 150px;
    .col-md-5{
      padding: 0;
    }
    .container{
      max-height: calc(110vh - 100px);

    }
    .small-image-container{
      display: flex;
      justify-content: space-between;
    }
    .item-image{
      overflow: hidden;
      display: inline-block!important;
      align-items: center !important;

      &.large-image{
        max-height: calc(100vh - 280px);
        padding: 0;
      }
      &.small-image{
        max-height: calc(((80vh - 180px) / 2) - 7px);
        margin-bottom: 0;
        margin-top: 2vh;
        padding: 0;
        display: flex;
        max-width: calc((100% - 2vh)/2);
        
        &:last-child{
          margin-left: 2vh;
          
        }
      }
      img{
        cursor: pointer;
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
      }
    }
  }
}

.button-section{
  margin-top: 10px;
.cursor-pointer{
  max-width: 11rem;
  cursor: pointer;
}
  .button-ver-mas{
    color: #3F6C6C;
    font-weight: 700;
    
  }

  img{ max-height: 25px;

  }
}
