.about-us{
  padding-top: 0;
  &__agents{
    padding-top: 3rem;
  }
}
.agent-card{
  &__profile{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 30px;
    overflow: hidden;
    .profile{
      max-width: 100%;
      display: block;
    }
  }
  &__body{
    margin-bottom: 100px;
    font-family: 'Roboto', sans-serif;
    .name{
      font-size: 1.5rem;
      color: $main-color;
      margin-bottom: 8px;
      @media (min-width: 992px){
        margin-bottom: 10px;
        font-size: 1.25rem;
      }
      @media (min-width: 1367px){
        margin-bottom: 10px;
        font-size: 1.5rem;
      }
    }
    .position{
      color: $main-color;
      font-size: 16px;
      margin-bottom: 15px;
      @media (min-width: 992px){
        font-size: 14px;
      }
    }
    .contact-info{
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      @media (min-width: 992px){
        font-size: 13px;
      }
      .ft-text{
        color: #191919;
        img{
          max-width: 14px;
          max-height: 14px;
          margin-right: 10px;
          margin-bottom: -2px;
          @media (min-width: 992px){
            max-width: 13px;
            max-height: 13px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}