.property-card {
  border: 1px solid #9797977a;
  border-radius: 10px;
  &:hover {
    box-shadow: 0px 15px 45px #0000001a;
  }
  figure {
    border-radius: 7px;
    overflow: hidden;
  }
  &__image {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;

    @media screen and (min-width: 1440px) {
      height: 175px;
    }
    @media screen and (min-width: 1920px) {
      height: 170px;
    }
  }
  &__body {
    position: relative;
    padding: 15px 15px;
    @media (min-width: 992px) {
      padding: 10px 15px 10px 15px;
    }
    @media (min-width: 1367px) {
      padding: 15px 15px;
    }
    .btn-circle {
      color: #ffffff;
      font-size: 26px;
      border-radius: 30px;
      box-shadow: 0px 15px 45px #0000004d;
      background: $main-color;
      padding: 5px 14px;
      line-height: normal;
      cursor: pointer;
      border: 0;
      position: absolute;
      top: -25px;
      right: 15px;
      &:focus {
        outline: none;
      }
      @media (min-width: 992px) {
        font-size: 20px;
        padding: 7px 13px;
      }
    }
    h3 {
      color: $main-color;
      font-family: "Roboto", sans-serif;
      font-size: 20px; /* 23px */
      margin-bottom: 5px;
      @media (min-width: 992px) {
        font-size: 16px;
        font-weight: 500;
        min-height: 55px;
      }
      @media (min-width: 1367px) {
        font-size: 23px;
      }
    }
    h6 {
      color: $main-color;
      font-family: "Roboto", sans-serif;
      font-size: 14px; /* 12px */
      margin-bottom: 15px;
      @media (min-width: 992px) {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
    p {
      color: $black-color;
      font-family: "Roboto", sans-serif;
      font-size: 13px; /* 12px */

      @media (min-width: 992px) {
        font-size: 12px;
      }
      @media (min-width: 1367px) {
        font-size: 15px;
      }
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    }
  }
  &__feature {
    padding: 0 15px;
    .icon__group {
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 1px solid #9797977a;
      border-bottom: 1px solid #9797977a;
      @media (min-width: 992px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      @media (min-width: 1367px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .icon {
        font-size: 15px; /* 15px */
        color: $main-color;
        text-align: center;
        @media (min-width: 992px) {
          font-size: 12px;
        }
        img {
          width: 100%;
          max-width: 28px;
          min-height: 25px;
          max-height: 25px;
          margin-bottom: 10px;
          @media (min-width: 992px) {
            max-width: 25px;
            min-height: 20px;
            max-height: 20px;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
  &__footer {
    font-size: 18px; /* 18px */
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 1rem;
    @media (min-width: 992px) {
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 12px;
    }
    @media (min-width: 1367px) {
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 1rem;
    }
  }

  &__gallery {
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    padding: 0 25px 25px 25px;
    .slide {
      height: 100%;
      margin: auto 0;
    }

    .close-button {
      background: transparent;
      border: none;
      color: #9797977a;
      font-family: sans-serif;
      font-size: 50px;
      position: absolute;
      transition: all ease 0.3s;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: auto;

      @media screen and (min-height: 700px) {
        top: 10px;
        right: 15px;
        left: auto;
        bottom: auto;
      }

      &:hover {
        color: black;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
