@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face{
  src: url(../../fonts/Segoe-UI-Bold.woff);
  font-family: 'Segoe Bold';
}
@font-face{
  src: url(../../fonts/Segoe-UI.woff);
  font-family: 'Segoe';
}
@font-face{
  src: url(../../fonts/Segoe-UI-Italic.woff);
  font-family: 'Segoe Italic';
}
$main-color: #3F6C6C;
$black-color: #191919;
$bg-color: #EDEFEF;

body{
  font-family: Montserrat;
}
a{
  cursor: pointer;
  &:hover{
    text-decoration: none;
  }
}
p{
  font-family: 'Roboto', sans-serif;
}
.form-control{
  &:focus{
    outline: none;
    box-shadow: none;
  }
}
.alert{
  strong{
    font-weight: 700;
  }
}
.main-section{
  padding-top: 0;
  @media (min-width: 992px){
    padding-top: 0px;
  }
  @media (min-width: 1367px){
    padding-top: 0px;
  }
}
.text-segoe{
  line-height: 24px;
  font-family: 'Segoe', sans-serif;
  font-size: 16px;
  color: #555964;
  margin-bottom: 5px;
  @media (min-width: 992px){
    font-size: 17px;
  }
  strong{
    font-family: 'Segoe Bold';
  }
}
.container{
  @media (min-width: 1367px){
    max-width: 1400px
  }
}
.bg-gray{
  background-color: $bg-color;
}
.section-title{
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 5px;
  @media (min-width: 992px){
    font-weight: 500;
    font-size: 32px;
  }
  @media (min-width: 1367px){
    font-size: 44px;
  }
}
.section-subtitle{
  font-size: 1.1rem;
  margin-bottom: 5px;
  @media (min-width: 992px){
    font-size: 32px;
  }
  @media (min-width: 1367px){
    font-size: 44px;
  }
}
.button{
  color: $main-color;
  font-size: 14px; // 18px
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: 0;
  &:focus{
    outline: none;
  }
  &:hover{
    text-decoration: none;
    color: inherit;
  }
  &.btn--read-more{
    background: #3F6C6C;
    padding: 16px 40px;
    color: #FFFFFF;
    border-radius: 10px;
    margin-top: 10px;
    display: inline-block;
    &:hover{
      box-shadow: 0px 15px 45px #00000033;
    }
  }
  .spinner-border{
    width: 1rem;
    height: 1rem;
    border-width: .15em;
    margin-right: 7px;
    margin-bottom: 1px;
  }
}
.desktop{
  display: none;
  @media (min-width: 992px){
    display: block;
  }
}
.hero{
  background-size: 0 0;
  margin-top: 90px;
  padding-top: 0;
  &--image{
    border-radius: 0 0 15px 15px;
    overflow: hidden;
    @media (min-width: 992px){
      border-radius: 0;
    }
    img{
      display: block;
      max-width: 235%;
      margin-left: -67.5%;
      @media (min-width: 992px){
        max-width: 100%;
        margin-left: 0%;
      }
    }
  }
  &--title{
    position: absolute;
    width: 100%;
    top: 295px;
    color: #FFFFFF;
    text-align: center;
    @media (min-width: 992px){
      text-align: left;
      top: 400px;
      width: auto;
    }
    @media (min-width: 1367px){
      top: 550px;
    }
    .section-title{
      letter-spacing: 2px;
      font-size: 1rem;
      @media (min-width: 992px){
        font-size: 28px;

      }
      @media (min-width: 1367px){
        font-size: 36px;

      }
    }
  }
  &__content{
    .text{
      font-size: 16px;
      line-height: 26px;
      color: #555964;
    }
    @media (min-width: 992px){
      padding-top: 3rem;
    }
  }
}

.sell, .investments{
  padding-top: 0;
}

/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(23, 68, 68, 0.75);
  border-radius: 10px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal:hover, .hvr-shutter-out-horizontal:focus, .hvr-shutter-out-horizontal:active {
  color: white;
}
.hvr-shutter-out-horizontal:hover:before, .hvr-shutter-out-horizontal:focus:before, .hvr-shutter-out-horizontal:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  background-color: rgba(23, 68, 68, 0.75);
}