.sell{
  &__description{
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media (min-width: 992px) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    @media (min-width: 1367px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}