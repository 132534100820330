.popup{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  display: none;
  position: fixed;
  overflow-y: auto;
  padding-top: 70px;
  background: rgba(10, 10, 10, 0.85);
  @media (min-width: 992px){
    padding-top: 100px;
  }
  .row{
    margin-right: 0;
    margin-left: 0;
    .contact__form{
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-top: 0;
      background: #FFFFFF;
      border-radius: 10px;
    }
    .close{
      position: absolute;
      z-index: 1050;
      border-radius: 50%;
      right: 50px;
      top: 50px;
      cursor: pointer;
      background: #fff;
      padding: 6px 11px 7px;
      opacity: 1;
    }
  }
  &.active{
    display: block;
  }
}