.property{
  padding-bottom: 3rem;
  @media (min-width: 992px){
    margin-top: 0;
    
    padding-bottom: 0;
  }
  @media (min-width: 1367px){
    
  }
  .page-item{
    .page-link{
      padding: 0.5rem 0.5rem;
      font-size: 12px;
      @media (min-width: 768px){
        font-size: 16px;
        padding: 0.5rem 0.75rem;
      }
    }
    &.active{
      .page-link{
        background-color: $main-color;
        border-color: $main-color;
      }
    }
  }
}