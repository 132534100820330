.contact{
  padding-top: 3.5rem;
  padding-bottom: 3rem;
  background-color: $bg-color;
  @media (min-width: 1367px){
    padding-top: 100px;
    padding-bottom: 90px;
  }
  &__form{
    margin-top: 2rem;
    form{
      .input-group{
        margin-bottom: 10px;
        @media (min-width: 1367px){
          margin-bottom: 15px;
        }
        .input-group-prepend{
          .input-group-text{
            background-color: #ffffff;
            border: 1px solid #ced4da;
            border-right: 0;
            border-radius: 7px 0 0 7px;
            img{
              max-width: 20px;
            }
          }
        }
        .form-control{
          font-family: Arial;
          color: #8990A1;
          border-radius: 0 7px 7px 0;
          font-size: 16px;
          border: 1px solid #ced4da;
          border-left: 0;
          padding: 0.425rem 0.75rem 0.275rem 0;
          @media (min-width: 1367px){
            font-size: 18px;
          }
          &:focus{
            outline: 0;
            box-shadow: none;
          }
          &#message{
            padding-left: 0.75rem;
            height: 13rem;
            border-radius: 7px;
            border-left: 1px solid #ced4da;
            @media (min-width: 1367px){
              height: 16.75rem;
            }
          }
        }
      }
    }
  }
  &__map{
    margin-top: 2rem;
    .tabs{
      display: flex;
      margin-bottom: 48px;
      &__tab{
        display: flex;
        align-items: center;
        margin-right: 2rem;
        cursor: pointer;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          height: 2px;
          background-color: $main-color;
          left: 0;
          bottom: -10px;
          width: 0;
        }
        &--icon{
          img{
            width: 16px;
            margin-right: 5px;
          }
        }
        &--title{
          font-size: 16px;
          font-weight: 500;
          color: $main-color;
        }
        &.active{
          &::before{
            width: 100%;
          }
        }
      }
    }
  }
}
.contact-page{
  padding-top: 0;
  .hero__content{
    padding-bottom: 1.5rem;
  }
}