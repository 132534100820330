.searcher{
  @media (min-width: 992px){
    margin-bottom: 55px;
  }
  @media (min-width: 1367px){
    margin-bottom: 4rem;
  }
  &__input{
    margin-bottom: 1.75rem;
    @media (min-width: 1200px){
      display: none !important;
    }
    .input{
      background-color: #CDD2DE;
      color: #838A9A;
      border: none;
      min-height: 40px;
      width: 200px;
      padding: 18px 2px 15px 18px;
      margin-left: -15px;
      border-radius: 0 25px 25px 0;
      display: flex;
      justify-content: space-between;
      @media (min-width: 768px){
        min-width: 290px;
        border-radius: 25px 25px 25px 25px;
        margin-left: 0;
      }
      span{
        width: 45px;
        display: inline-block;
        margin-right: 10px;
        margin-top: -3px;
        img{
          max-height: 17px;
        }
      }
    }
    .list{
      width: 20px;
      @media (min-width: 1024px){
        display: none;
      }
    }
  }
  .advanced{
    display: none;
    padding: 50px 25px;
    width: 330px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 15px 45px #0000001A;
    font-family: 'Roboto', sans-serif;
    @media (min-width: 768px){
      width: 500px;
    }
    @media (min-width: 992px){
      width: 768px;
    }
    @media (min-width: 1200px){
      display: block;
      width: 100%;
      padding: 40px 15px;
    }
    @media (min-width: 1367px){
      padding: 50px 25px;
    }
    &.hide{
      @media (min-width: 1200px){
        display: none;
      }
    }
    .h4{
      @media (min-width: 1200px){
        font-size: 14px;
      }
      @media (min-width: 1367px){
        font-size: 1rem;
      }
    }
    .close{
      margin-top: -35px;
      margin-right: -6px;
      font-family: 'Montserrat', sans-serif;
      @media (min-width: 1200px){
        display: none;
      }
    }
    form{
      .form-group{
        position: relative;
        @media (min-width: 1200px){
          margin-bottom: 12px;
        }
        @media (min-width: 1367px){
          margin-bottom: 1rem;
        }
        label{
          position: absolute;
          left: 15px;
          top: 12px;
          color: #424753;
          @media (min-width: 1200px){
            top: 10px;
            font-size: 14px;
          }
          @media (min-width: 1367px){
            top: 12px;
            font-size: 1rem;
          }
        }
        .form-control{
          border: 0;
          border-radius: 20px;
          background-color: #CDD2DE;
          box-shadow: 0px 10px 20px #0000000D;
          color: #000000;
          font-size: 1rem;
          height: auto;
          padding: 30px 15px 10px;
          text-transform: uppercase;

          &:disabled {
            cursor: not-allowed;
          }

          @media (min-width: 1200px){
            padding: 25px 15px 4px;
            font-size: 0.9rem;
            border-radius: 15px;
          }
          @media (min-width: 1367px){
            padding: 30px 15px 10px;
            font-size: 1rem;
            border-radius: 20px;
          }
        }
        select{
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          background-repeat: no-repeat;
          background-position: 95% center;
          background-size: 15px 15px;
        }
      }
      button{
        border: 0;
        border-radius: 50%;
        background: $main-color;
        width: 40px;
        height: 40px;
        padding: 10px;
      }
    }
  }
  .popup{
    position: fixed;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    z-index: 1;
    top: 65px;
    height: calc(100% - 65px);
    align-items: center;
    .advanced{
      display: block;
      margin-top: 30px
    }
  }
}