.footer{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: $main-color;
  &__brands{
    li{
      img{
        width: 70%;
      }
    }
  }
  &__menu{
    display: none;
    @media (min-width: 992px){
      display: block;
    }
  }
  &__social{
    width: 100%;
    text-align: center;
    @media (min-width: 992px){
      width: 50%;
      text-align: inherit;
    }
    li{
      display: inline-block;
      @media (min-width: 992px) {
        display: inherit;
      }
      .media{
        align-items: initial;
        cursor: pointer;
        @media (min-width: 992px){
          align-items: flex-start;
        }
        img{
          height: 20px;
          width: 22px;
          @media (min-width: 992px){
            height: auto;
            width: auto;
            max-height: 18px;
            max-width: 16px;
          }
          
        }
        .media-body{
          display: none;
          @media (min-width: 992px){
            display: block;
          }
        }
      }
    }
  }
  &__newsletter{
    .form-control{
      border: 0;
      border-radius: 4px 0 0 4px;
      font-size: 12px;
    }
    .button{
      border-radius: 0 4px 4px 0;
      background-color: #EDEFEF;
      .input-group-text{
        font-size: 10px;
        background-color: $bg-color;
        border: 0;
        border-radius: 0 4px 4px 0 !important;
        cursor: pointer;
      }
    }
  }
  &__contact{
    text-align: center;
    @media (min-width: 992px){
      text-align: left;
    }
    li{
      @media (min-width: 992px){
        padding-left: 20px;
      }
    }
    .nombre-sucursal{
      font-weight: 700;
      padding-left: 0;
    }
    .media{
      display: inline-block;
      align-items: initial;
      @media (min-width: 992px){
        display: flex;
        align-items: flex-start;
      }
      img{
        max-height: 18px;
        max-width: 16px;
        &.tel{
          max-height: 18px;
          max-width: 16px;
        }
      }
    }
    .media-body{
      display: inline-block;
      @media (min-width: 992px){
        display: inherit;
      }
    }
  }
  ul{
    .ft-text{
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
}