/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 1;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  border: 0;
  cursor: pointer; }

  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 42%;
    background-image: url(/images/arrow-alt-circle-left-regular-white.svg);
    @media (min-width: 992px){
      left: 10%;
    }
    @media (max-width: 991px){
      background-image: url(/images/arrow-alt-circle-left-regular.svg);
    }
  }
    .carousel .control-prev.control-arrow:hover{
      opacity: 1;
    }
  .carousel .control-next.control-arrow {
    right: 42%;
    background-image: url(/images/arrow-alt-circle-right-regular-white.svg);
    @media (min-width: 992px){
      right: 10%;
    }
    @media (max-width: 991px){
      background-image: url(/images/arrow-alt-circle-right-regular.svg);

    }
  }
  
  .carousel .control-next.control-arrow:hover{
    opacity: 1;
  }

.carousel-root {
  outline: none; }

.carousel {
  position: relative;
  width: 100%; }
  .carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
    object-fit: cover
    }
    @media (max-width: 815px){
      .carousel img, .slide-mobile, .slide-desktop {
        height: 59vh;
        max-height: none;

      }
    }
    @media (max-width: 1199px){
      .carousel img, .slide-mobile, .slide-desktop {
        max-height: calc(100vh - 90px);
      }
    }
    @media (min-width: 1200px){
      .carousel img, .slide-mobile, .slide-desktop {
        height: calc(100vh - 90px);
      }
    }
    @media (min-width: 1367px){
      .carousel img, .slide-mobile, .slide-desktop {
        height: calc(100vh - 90px);
      }
    }
  .carousel .carousel {
    position: relative; }
  .carousel .control-arrow {
    outline: 0;
    border: 0;
    width: 30px;
    height: 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px;
    background-color: transparent;
    opacity: 0.8;
    top: 40%; }
    @media (min-width: 992px){
      .carousel .control-arrow {
        width: 35px;
        height: 35px;
        background-size: 30px;
        opacity: 0.7;}
    }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden; }
  .carousel .thumbs {
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap; }
  .carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -ms-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px; }
    .carousel .thumb:focus {
      border: 3px solid #ccc;
      outline: none; }
    .carousel .thumb.selected, .carousel .thumb:hover {
      border: 3px solid #333; }
    .carousel .thumb img {
      vertical-align: top; }
  .carousel.carousel-slider {
    position: relative;
    margin:112px 0 0;
    @media (min-width: 992px){
      overflow: hidden;
    }
  }
    .carousel.carousel-slider .control-arrow {
      top: 105%;
      color: #fff;
      padding: 0;
      @media (min-width: 992px){
        top: 40%;
      }
    }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    -moz-transition: height 0.15s ease-in;
    -ms-transition: height 0.15s ease-in;
    -o-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in; }
    .carousel .slider-wrapper.axis-horizontal .slider {
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-horizontal .slider .slide {
        flex-direction: column;
        flex-flow: column; }
    .carousel .slider-wrapper.axis-vertical {
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-vertical .slider {
        -webkit-flex-direction: column;
        flex-direction: column; }
  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%; }
    .carousel .slider.animated {
      -webkit-transition: all 0.35s ease-in-out;
      -moz-transition: all 0.35s ease-in-out;
      -ms-transition: all 0.35s ease-in-out;
      -o-transition: all 0.35s ease-in-out;
      transition: all 0.35s ease-in-out; }
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: transparent; }

    .carousel .slide img {
      width: 100%;
      vertical-align: top;
      border: 0; }
    .carousel .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0; }
    .carousel .slide .legend {
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: #000;
      color: #fff;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      -webkit-transition: opacity 0.35s ease-in-out;
      -moz-transition: opacity 0.35s ease-in-out;
      -ms-transition: opacity 0.35s ease-in-out;
      -o-transition: opacity 0.35s ease-in-out;
      transition: opacity 0.35s ease-in-out; }
      @media (max-width: 768px){
        // .carousel .slide {
        //   overflow: hidden;
        // }
        // .carousel .slide img{
        //   width: 280%;
        //   margin-left: -90%;
        // }
      }
      @media (max-width: 600px){
        // .carousel .slide {
        //   overflow: hidden;
        // }
        // .carousel .slide img{
        //   width: 250%;
        //   margin-left: -75%;
        // }
      }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 960px) {
      .carousel .control-dots {
        bottom: 0; } }
    .carousel .control-dots .dot {
      -webkit-transition: opacity 0.25s ease-in;
      -moz-transition: opacity 0.25s ease-in;
      -ms-transition: opacity 0.25s ease-in;
      -o-transition: opacity 0.25s ease-in;
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: #fff;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px; }
      .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff; }
    .content-info{
      display: block;
      height: 140px;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: left;
      padding-top: 10px;
      padding-left: 10%;
      font-family: Montserrat;
      color: white;
      text-shadow: 1px 2px 3px rgba(51, 51, 51, 1);
      @media (min-width: 768px){
        height: 80px;
      }
      @media (min-width: 992px){
        height: 200px;
      }
      @media (min-width: 1367px){
        height: 300px;
      }
    }
    .content-info h1{
      font-size: 1.75rem;
      margin-bottom: 5px;
      font-weight: 400;
      @media (min-width: 992px){
        font-size: 3rem;
      }
      @media (min-width: 1367px){
        font-size: 4.6rem;
      }
    }
    .content-info p{
      @media (min-width: 992px){
        font-size: 1.5rem;
        font-weight: 400;
      }
      @media (min-width: 1367px){
        font-size: 2.25rem;
      }
    }

    .slide{
      .button-wp{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        display: flex;
        width: 60px;
        height: 60px;
        @media (min-width: 992px){
          bottom: 3rem;
          right: 2rem;
        }
        img{
          width: 30px;
          vertical-align: initial;
          border: initial;
          height: auto !important;
          margin-left: auto;
          margin-right: auto;
        }
      }
      &-mobile {
        @media screen and (min-width: 600px) {
          display:none;
        }
      }
      &-desktop {
        display: none;

        @media screen and ( min-width: 600px) {
          display: block;
        }
      }
    }


    //Nuevo Carrousel

    .slide-container {
      position: relative;
      background-color: #000;


    }

    .slide-container > img {
      position: relative;
      background-color: #000;
      opacity: 0.5;

    }
    
    .slide-content {
      position: absolute;
      top: 30%;
      left: 15%;
      // transform: translate(-50%, -50%);
      text-align: left;
      color: #ffffff;
    }
    
    .slide-content h1 {
      font-size: 4vh;
      margin-bottom: 5px;
      font-weight: 700;
      max-width: 800px;

    }
    
    .slide-content p {
      font-size: 3vh;
      margin-bottom: 20px;
      max-width: 428px;
      line-height: 4vh;


    }
    .logo-emprendimientos{
      width: auto!important;
      max-width: 250px!important;
      height: 120px!important;
      margin-bottom: 10px;
      object-fit: contain!important;

    }
    
    .slide-content button {
      background-color: #ffffff;
      color: #000000;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
    }

    @media (max-width: 815px){
      .slide-content {

        left: 10%;

      }
      .slide-content h1 {
        font-size: 6vh;
        margin-bottom: 10px;
        font-weight: 700;
        max-width: 800px;
  
      }
      
      .slide-content p {
        font-size: 2.6vh;
        margin-bottom: 20px;
        max-width: 450px;
        line-height: 4vh;
  
  
      }
    }

    @media (max-width: 600px){
      .slide-content {
        position: absolute;
        top: 34%;
        left: 5%;
        // transform: translate(-50%, -50%);
        text-align: left;
        color: #ffffff;
      }
      
      .slide-content h1 {
        font-size: 3vh;
        margin-bottom: 10px;
        font-weight: 700;
        max-width: 350px;
  
      }
      
      .slide-content p {
        font-size: 1.95vh;
        margin-bottom: 20px;
        max-width: 250px;
        line-height: 3vh;
  
  
      }
    }
    
