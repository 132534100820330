#placeholder {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1030;
  background-color: #FFFFFF;
  .d-flex {
    width: 100%;
    height: 100%;
    .text-normal{
      color: #247178;
    }
  }
}