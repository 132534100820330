.services{
  padding-top: 0;
  &__type{
    padding-top: 3rem;
    .section-title{
      margin-bottom: 2.5rem;
      @media (min-width: 1367px){
        margin-bottom: 4rem;
      }
    }
    .type-card{
      padding-top: 2rem;
      &__icon{
        height: 40px;
        margin-bottom: 15px;
        width: 100%;
        max-width: 48px;
        margin-right: 15px;
        @media (min-width: 1367px){
          height: 58px;
          margin-bottom: 30px;
          max-width: 75px;
        }
        img{
          max-height: 100%;
        }
      }
      &__title{
        // margin-bottom: 50px;
        font-family: 'Roboto', sans-serif;
        .h2{
          font-size: 1.2rem; /* 1.5rem */
          color: $main-color;
          // color: #707070;
          margin-bottom: 10px;
          @media (min-width: 575px){
            font-size: 14px;
          }
          @media (min-width: 992px){
            margin-bottom: 15px;
            font-size: 1.25rem;
          }
          @media (min-width: 1367px){
            margin-bottom: 30px;
            font-size: 1.5rem;
          }
        }
      }
      // &.active{
      //   .h2{
      //     color: $main-color;
      //   }
      // }
    }
  }
  .description{
    margin-bottom: 3rem;
    .text-segoe{
      @media (min-width: 992px) {
        font-size: 15px;
      }
      @media (min-width: 1367px) {
        font-size: 17px;
      }
    }
    li{
      /* list-style: disc; */
      list-style: inside;
    }
  }
}