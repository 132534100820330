.blog{
  padding-top: 0;
  &__content{
    .row{
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #9797977a;
    }
    h3{
      font-size: 24px;
      color: $main-color;
      line-height: 30px;
      margin-bottom: 1.5rem;
    }
    figure{
      margin-bottom: 1rem;
      margin-top: 1rem;
      @media (min-width: 992px){
        margin-bottom: 2rem;
        margin-top: 0;
      }
      @media (min-width: 1367px){
        margin-bottom: 3rem;
      }
      img{
        display: block;
      }
    }
  }
  .share{
    display: none;
    padding-bottom: 5rem;
    @media (min-width: 992px){
      display: block;
    }
    ul{
      li{
        font-weight: 700;
        color: $main-color;
        line-height: 22px;
        letter-spacing: 2.57px;
        padding-right: 2rem;
        width: 100%;
        font-size: 15px;
        img{
          margin-right: 15px;
          max-width: 25px;
          max-height: 25px;
        }
      }
    }
  }
}
.open-popover{
  position: relative;
  cursor: pointer;
  .popover{
    left: 0;
    top: 100%;
    width: 100%;
    .popover-body{
      display: flex;
      justify-content: space-around;
    }
  }
}