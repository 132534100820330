.button-wp{
  position: fixed;
  z-index: 1050;
  bottom: 2rem;
  right: 2rem;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  cursor: pointer;
  background-color: $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1367px){
    width: 75px;
    height: 75px;
    bottom: 3rem;
    right: 3.5rem;
  }
  img{
    width: 30px;
    margin-bottom: 1px;
    @media (min-width: 1367px){
      width: 35px;
    }
  }
}