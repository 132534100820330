/* LAYOUT - Estilos generales de la pagina, contenedores */
.top-menu{
  background: #247178;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1010;
  height: 32px;
  .redes-sociales{
    display: flex;
  }
  .phone-number{
    margin-right: 30px;
    @media (max-width: 768px){
      margin-right: auto;
    }
    a{
      color: white;
      font-size: 14px;
    }
  }
}
.header {
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  top: 32px;
  left: 0;
  width: 100%;
  z-index: 1010;
  height: auto;
  box-shadow: 0px 15px 45px #0000001a;

  .row {
    height: 80px;
    transition: all 0.4s;
    .header__logo {
      max-width: 200px;
      position: relative;
      z-index: 2;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}
.header-item-group {
  color: #424753;
  text-align: right;
  @media (min-width: 1367px) {
    max-width: 900px;
    margin-left: auto;
  }
}
.header-item-group__item {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-top: 34.5px;
  padding-bottom: 34.5px;
  font-size: 0.9rem;
  text-align: center;
  position: relative;
  @media (min-width: 1367px) {
    font-size: 1rem;
  }
  &.emprendimiento{
    @media (min-width: 1367px) {
      padding-left: 25px;
      padding-right: 15px;
    }
  }
}

.header-item-group__item .item-link {
  color: #424753;
}
.header-item-group__item:hover > .item-link {
  font-weight: 700;
  text-decoration: none;
}
.header-item-group__item .item-link {
  color: inherit;
  cursor: pointer;
  width: 100%;
  z-index: 1;
  position: relative;
}
.header-item-group__item > .item-link.active{
  font-weight: 700;
}
.sub-group {
  position: absolute;
  top: 0;
  padding-bottom: 15px;
  background: #ffffff;
  z-index: 0;
  box-shadow: 0px 15px 45px #0000001a;
  border-radius: 0 0 4px 4px;
  transition: all 0.5s;
  opacity: 0;
}
.sub-group .sub-item {
  padding: 15px 2px;
  width: 100%;
  display: inline-block;
  font-size: 11px;
  display: none;
}
.sub-group .sub-item:hover > .item-link {
  font-weight: 700;
  text-decoration: none;
}
.header-item-group__item:hover .sub-group {
  padding-top: 60px;
  height: auto;
  opacity: 1;
}
.header-item-group__item:hover .sub-group .sub-item {
  display: inline-block;
}
@media screen and (max-width: 1199.98px) {
  .header-item-group__item {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-item-group__item:hover .sub-group {
    padding-top: 0;
  }
}
@media screen and (max-width: 991.98px) {
  .header-item-group {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    padding-top: 140px;
    padding-left: 35px;
    margin-top: auto;
    background: #ffffff;
    margin-bottom: auto;
    transition: all 1s;
    z-index: 0;
    text-align: left;
    box-shadow: 0px 15px 45px #0000001a;
    border-radius: 0px 0px 6px 6px;
    padding-bottom: 2.5rem;
  }
  .header-item-group__item {
    list-style: none;
    display: block;
    margin-bottom: 15px;
    text-align: left;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .sub-group {
    position: relative;
    top: 0;
    padding-bottom: 0;
    background: #ffffff;
    z-index: 0;
    box-shadow: none;
    border-radius: 0;
    opacity: 1;
    height: auto;
  }
  .sub-group .sub-item {
    padding: 15px 0 5px 10px;
    width: 100%;
    display: inline-block;
    font-size: 13px;
  }
  .sub-group .sub-item:last-child {
    padding-bottom: 0;
  }
  .header-item-group__item > .item-link {
    font-weight: 700;
    text-decoration: none;
  }
}
@media screen and (max-width: 991.98px) and (max-width: 575.98px) {
  .header-item-group__item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media screen and (max-width: 991.98px) {
  .header-item-group.active {
    left: -0;
  }
}
.top-menu .rrss {
  max-width: 45px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 10px;
  margin-bottom: 0px;
}
.top-menu .rrss span {
  color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  cursor: pointer;
  margin-left: auto;
  text-align: center;
}
.top-menu .rrss span img {
  width: 13px;
  margin-top: 6px;
  cursor: pointer;
}
.top-menu .rrss.yt span img {
  width: 16px;
  margin-top: 7px;
}
.top-menu .rrss.ig {
  padding-left: 2px;
  padding-right: 0;
}
.header .navbar {
  display: none;
  padding-top: 16px;
  padding-bottom: 15px;
  padding-left: 0;
}
.header .navbar .nav-button {
  position: relative;
  z-index: 1020;
  display: block;
  width: 30px;
  line-height: 1;
  margin-left: auto;
}
// @media screen and (max-width: 991.98px) {
//   .header .navbar .nav-button {
//     margin-right: auto; } }
.header .navbar .nav-button #nav-icon3 {
  width: 24px;
  height: 17px;
  display: inline-block;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.header .navbar .nav-button #nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #424753;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.header .navbar .nav-button #nav-icon3 span:nth-child(1) {
  top: 0;
}
.header .navbar .nav-button #nav-icon3 span:nth-child(2) {
  top: 7px;
}
.header .navbar .nav-button #nav-icon3 span:nth-child(3) {
  top: 7px;
}
.header .navbar .nav-button #nav-icon3 span:nth-child(4) {
  top: 14px;
}
.header .navbar.nav-open #nav-icon3 span:nth-child(1) {
  top: 9px;
  width: 0;
  left: 50%;
}
.header .navbar.nav-open #nav-icon3 span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header .navbar.nav-open #nav-icon3 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.header .navbar.nav-open #nav-icon3 span:nth-child(4) {
  top: 9px;
  width: 0;
  left: 50%;
}
@media screen and (max-width: 991.98px) {
  .header .navbar {
    display: block;
  }
}

/* Header Active */
.header {
  .row {
    .header-item-group__item {
      transition: all 0.4s;
    }
  }
  &.active {
    .row {
      height: 65px;
      @media (min-width: 991.98px) {
        .header-item-group__item {
          padding-top: 26px;
          padding-bottom: 25px;
        }
      }
    }
  }
}
